import React from 'react';
import Button from '../components/Button';
import { Column, Container, Row } from '../components/Grid';
import Hero from '../components/Hero';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Section from '../components/Section';

const LiteracyApproachPage = ({ data }) => (
  <Layout title="Literacy Approach">
    <Hero
      mediaSource="literacyapproach-hero.png"
      className="literacy-hero mb-0"
    >
      <Container noGutters className="literacy-hero__content">
        <Image
          filename="literacyapproach-hero-cosmos-leftside.png"
          className="literacy-hero__content__cosmos-left"
        />
        <Image
          filename="literacyapproach-hero-astronaut.png"
          className="literacy-hero__content__astronaut"
        />
        <h1 className="title literacy-hero__content__title">
          Literacy <br /> <br />
          Approach
        </h1>
        <Image
          filename="literacyapproach-hero-planet.png"
          className="literacy-hero__content__planet"
        />
      </Container>
    </Hero>
    <Section className="literacy-focus">
      <Container>
        <h1>Leveraging Literacy for Everyday Life</h1>
        <Row className="mt-1">
          <Column size={6}>
            <Image filename="literacyapproach-focus.jpg" />
          </Column>
          <Column size={6}>
            <p>
              <i>Discover Literacy</i> resources are designed to ignite
              innovative and engaging approaches to teaching and literacy
              basics. Educators, students, and families can integrate these
              resources into their everyday lives to strengthen their literacy
              skills and become confident readers.
            </p>
            <p>
              Guide learning with thematically structured resources that harness
              the 5 Basic Skills of Literacy: Phonological Awareness, Phonics,
              Fluency, Vocabulary, and Reading Comprehension.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="literacy-framework">
      <Container className="literacy-framework__container">
        <h1 className="literacy-framework__container__title">
          5 Basic Skills of Literacy
        </h1>
        <div className="literacy-framework__container__component1">
          <Image
            filename="literacyapproach-framework-component1.jpg"
            className="literacy-framework__container__component1__image"
          />
          <div className="literacy-framework__container__component1__text">
            <h2>Phonological Awareness</h2>
            <h3>Get Students into the Rhythm of Spoken Words</h3>
            <p>
              Students develop an “ear for language” as they become familiar
              with the core sounds of words.
            </p>
            <ul>
              <li>
                <p>Rhythm and Rhyme</p>
              </li>
              <li>
                <p>Sequence of Sounce</p>
              </li>
              <li>
                <p>Sound Manipulation</p>
              </li>
            </ul>
          </div>
          <Image
            filename="literacyapproach-balloon.png"
            className="literacy-framework__container__component1__balloon-image"
          />
        </div>
        <div className="literacy-framework__container__component2">
          <div className="literacy-framework__container__component2__text">
            <h2>Phonics</h2>
            <h3>Building a Sound Understanding of Words and Language</h3>
            <p>
              Students establish an understanding of the relationship between
              the visual representation of a word and its corresponding sound.
            </p>
            <ul>
              <li>
                <p>Alphabetic Principle</p>
              </li>
              <li>
                <p>Decoding</p>
              </li>
              <li>
                <p>Word Study</p>
              </li>
            </ul>
          </div>
          <Image
            filename="literacyapproach-skills-phonics.jpg"
            className="literacy-framework__container__component2__image"
          />
          <Image
            filename="literacyapproach-skills-phonics-cloud.svg"
            className="literacy-framework__container__component2__cloud"
          />
        </div>
        <div className="literacy-framework__container__component3">
          <Image
            filename="literacyapproach-skills-fluency.jpg"
            className="literacy-framework__container__component3__image"
          />
          <div className="literacy-framework__container__component3__text">
            <h2>Fluency</h2>
            <h3>Celebrate the Real-World Power of Reading Skills</h3>
            <p>
              Students learn a simple 3-part formula that can be used to achieve
              effective reading fluency.
            </p>
            <ul>
              <li>
                <p>Automaticity</p>
              </li>
              <li>
                <p>Reading Speed</p>
              </li>
              <li>
                <p>Prosody</p>
              </li>
            </ul>
          </div>
          <Image
            filename="literacyapproach-skills-fluency-lightbulb.png"
            className="literacy-framework__container__component3__lightbulb"
          />
        </div>
        <div className="literacy-framework__container__component4">
          <div className="literacy-framework__container__component4__text">
            <h2>Vocabulary</h2>
            <h3>Give Students the Building Blocks of Quality Communication</h3>
            <p>
              Students will learn how to grow their knowledge of words and build
              their vocabulary from the ground up.
            </p>
            <ul>
              <li>
                <p>Tiered Vocabulary Strategies</p>
              </li>
              <li>
                <p>Active Vocab</p>
              </li>
              <li>
                <p>Practice Pro-Tips</p>
              </li>
            </ul>
          </div>
          <Image
            filename="literacyapproach-skills-vocabulary.jpeg"
            className="literacy-framework__container__component4__image"
          />
        </div>
        <div className="literacy-framework__container__component5">
          <Image
            className="literacy-framework__container__component5__image"
            filename="literacyapproach-skills-reading.jpg"
          />
          <div className="literacy-framework__container__component5__text">
            <h2>Reading Comprehension</h2>
            <h3>Decoding Language to Unlock Students’ World</h3>
            <p>
              Students uncover the real-world connections and meaning behind the
              language that people use.
            </p>
            <ul>
              <li>
                <p>Background Knowledge</p>
              </li>
              <li>
                <p>Teaching Strategies</p>
              </li>
              <li>
                <p>Text Structures</p>
              </li>
            </ul>
          </div>
          <Image
            filename="literacyapproach-skills-reading-cloud.svg"
            className="literacy-framework__container__component5__cloud"
          />
        </div>
      </Container>
    </Section>
    <Section className="literacy-about">
      <Container className="literacy-about__container">
        <div className="literacy-about__container__yellow-bar"></div>
        <div className="literacy-about__container__blue-arrow">
          <Image
            className="literacy-about__container__blue-arrow--right"
            filename="home-blueArrow.svg"
          />
          <Image
            className="literacy-about__container__blue-arrow--up"
            filename="home-blueArrowUp.svg"
          />
        </div>
        <div className="literacy-about__container__text">
          <h1>About the Program</h1>
          <p>
            <i>Discover Literacy</i> empowers educators with turnkey,
            research-based resources that make it easy to incorporate literacy
            upskilling into any schedule or curricular framework. Educators,
            students, and families can find exciting ways to explore literacy
            with a combination of professional microlearnings and hands-on
            instructional resources.
          </p>

          <p>
            The Dollar General Literacy Foundation, Discovery Education, and the
            National Afterschool Association have designed Discover Literacy
            resources to align with the 5 Basic Competencies of Literacy, which
            provide a framework students can use to practice literacy skills in
            their daily lives.
          </p>
          <Button to="/about">Learn More</Button>
        </div>
        <Image
          filename="literacyapproach-about.png"
          className="literacy-about__container__right-image"
        />
      </Container>
    </Section>
    <Section className="literacy-quote">
      <Container className="literacy-quote__container">
        <Row>
          <Column size={2} className="literacy-quote__container__book">
            <Image filename="literacyapproach-quote-book.png" />
          </Column>
          <Column size={8} className="literacy-quote__container__text">
            <h1>
              Early academic skills related to literacy are among the most
              significant predictors of future academic achievement.
            </h1>
            <p className="large mt-2">—Hanover Research</p>
          </Column>
          <Column size={2} className="literacy-quote__container__astronaut">
            <Image filename="literacyapproach-quote-astronaut.png" />
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default LiteracyApproachPage;
